import { createAction } from '@reduxjs/toolkit';
import { FileEntity } from '../../../types/entities/file';

export enum Keys {
    ADD = '[ Validation files ] Add error',
}

export const addErrors = createAction('validationFiles/addError', (file: FileEntity, errors: string[]) => {
    return {
        payload: file,
        meta: errors,
    };
});
