import axios from 'axios';
import { FileEntity } from '../../../types/entities/file';
import { getApi, getProjectId } from '../../../utils/helpers';

export const loadFile = async (sectionHash: string, fileId: number) =>
    axios
        .get<FileEntity>(`${getApi()}/projects/${getProjectId()}/sections/${sectionHash}/files/${fileId}`)
        .then((r) => r.data);

export const getThumbUrl = (file: FileEntity) =>
    axios.get<{ thumb: string }>(
        `${getApi()}/projects/${getProjectId()}/sections/${file.section}/files/${file.id}/thumbnail`,
    );

export const deleteFile = (file: FileEntity) =>
    axios.delete(`${getApi()}/projects/${getProjectId()}/sections/${file.section}/files/${file.id}`);

export const problematicFile = (sectionHash: string, fileId: number) =>
    axios.put(`${getApi()}/projects/${getProjectId()}/sections/${sectionHash}/files/${fileId}/mark-file-defect`);
