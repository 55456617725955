import { createAction } from '@reduxjs/toolkit';
import { FileEntity } from '../../../types/entities/file';

export const add = createAction<FileEntity>('files/add');

export const update = createAction<FileEntity>('files/update');

export const remove = createAction<FileEntity>('files/remove');

export const addThumb = createAction('files/addThumb', (file: FileEntity, thumb: string) => {
    return {
        payload: file,
        meta: thumb,
    };
});
