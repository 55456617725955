import { UploadListItemEntity } from '../../../types/entities/upload-list-item';
import { checkDimensions, checkPDFDimensions } from '../../../utils/helpers';
import store from '../../../utils/store';
import { ProjectSectionEntity } from '../../../types/entities/project-section';
import { DEFAULT_DPI } from '../../../utils/config';
import { FileEntity } from '../../../types/entities/file';
import { __ } from '../../../utils/i18n';
import { forEach } from 'lodash-es';
import { VersionType } from '../../../types/shared';
import { PDFPage } from 'pdf-lib';

type SizeType = {
    width: number;
    height: number;
};

const checkDataStorageItem = (sectionHash: string, uploadItem: UploadListItemEntity) => {
    const data = store.getState().data[sectionHash] as FileEntity[];

    if (data.findIndex((item) => item.name === uploadItem.file.name) !== -1) {
        return __('Wybrany plik już został dodany');
    }

    return null;
};

const checkFileDimensions = (config: ProjectSectionEntity, uploadItem: UploadListItemEntity) => {
    if (config.files.size && uploadItem.dimensions) {
        const { width, height } = config.files.size;
        const { width: fileWidth, height: fileHeight } = uploadItem.dimensions;

        return checkDimensions(fileWidth, fileHeight, width, height, DEFAULT_DPI);
    }

    return null;
};

const checkPDFPagesSize = (configSize: SizeType | undefined, pdfPagesSize: Array<SizeType>) => {
    if (!configSize) {
        return null;
    }

    const { width, height } = configSize;
    let sizeErrorMsg = null;

    forEach(pdfPagesSize, (size, index) => {
        sizeErrorMsg = checkPDFDimensions(size.width, size.height, width, height, index);

        if (sizeErrorMsg) {
            return;
        }
    });

    if (sizeErrorMsg) {
        return sizeErrorMsg;
    }

    return null;
};

const checkPDFPageCount = (pagesCount: number, pdfPagesPerFile: { max: number; min: number } | undefined) => {
    if (!pdfPagesPerFile) {
        return null;
    }

    if (pagesCount > pdfPagesPerFile.max || pagesCount < pdfPagesPerFile.min) {
        let message = __('Nieprawidłowa ilość stron');

        if (pdfPagesPerFile.max === pdfPagesPerFile.min) {
            message += `. ${__('Wymagana ilość stron')}` + `: ${pdfPagesPerFile.min}`;
        } else {
            message +=
                `. ${__('Wymagana ilość stron')}` + `: min - ${pdfPagesPerFile.min}` + ` max - ${pdfPagesPerFile.max}`;
        }

        return message;
    }

    return null;
};

const checkFileNameLength = (fileName: string) => {
    if (fileName.length > 250) {
        return __('Plik ma za długą nazwę');
    }

    return null;
};

const checkPDFVersion = (pdfVersion: VersionType, configMinVersion?: string) => {
    if (!configMinVersion) {
        return null;
    }

    const version = Number(`${pdfVersion.major}.${pdfVersion.minor}`);

    console.log(version, Number(configMinVersion));

    if (version < Number(configMinVersion)) {
        return __('Poprawna wersja pliku PDF to %s lub nowsza', [configMinVersion]);
    }

    return null;
};

export const checkIfPDFProblematic = (pages: PDFPage[]): boolean => {
    const pagesInfo = [
        pages[0].getMediaBox(),
        pages[0].getCropBox(),
        pages[0].getBleedBox(),
        pages[0].getTrimBox(),
        pages[0].getArtBox(),
    ];

    const pageProblematic = pagesInfo.some((page) => page.x != 0 || page.y != 0);

    return pageProblematic;
};

export const validateFile = (sectionHash: string, uploadItem: UploadListItemEntity): boolean | string => {
    const config = store.getState().config[sectionHash] as ProjectSectionEntity;
    const dataStorageError = checkDataStorageItem(sectionHash, uploadItem);

    if (dataStorageError) {
        return dataStorageError;
    }

    const fileDimensionsError = checkFileDimensions(config, uploadItem);

    if (fileDimensionsError) {
        return fileDimensionsError;
    }

    if (uploadItem.pdf) {
        const { size, pdfPagesPerFile, pdfMinVersion } = config.files;

        const pdfVersionError = checkPDFVersion(uploadItem.pdf.version, pdfMinVersion);

        if (pdfVersionError) {
            return pdfVersionError;
        }

        const pdfPageSizeError = checkPDFPagesSize(size, uploadItem.pdf.sizes);

        if (pdfPageSizeError) {
            return pdfPageSizeError;
        }

        const pdfPageCountError = checkPDFPageCount(uploadItem.pdf.pagesCount, pdfPagesPerFile);

        if (pdfPageCountError) {
            return pdfPageCountError;
        }
    }

    const fileNameLengthError = checkFileNameLength(uploadItem.file.name);

    if (fileNameLengthError) {
        return fileNameLengthError;
    }

    return true;
};
