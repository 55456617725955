import { FileEntity } from '../../../types/entities/file';
import { checkDimensions } from '../../../utils/helpers';
import store from '../../../utils/store';
import { DEFAULT_DPI } from '../../../utils/config';
import { ProjectSectionEntity } from '../../../types/entities/project-section';

export const validateFile = (file: FileEntity): boolean | string => {
    const config = store.getState().config[file.section] as ProjectSectionEntity;

    if (config.files.size && file.width && file.height) {
        const { width, height } = config.files.size;

        return checkDimensions(file.width, file.height, width, height, DEFAULT_DPI);
    }

    return true;
};
