import * as React from 'react';
import { connect } from 'react-redux';
import map from 'lodash-es/map';
import { RootState } from '../../../utils/store';
import { ProjectSectionEntity } from '../../../types/entities/project-section';
import Section from './section';

interface Props {
    sectionHashes: string[];
}

const Preview = (props: Props) => {
    const { sectionHashes } = props;

    const renderSections = () => {
        return sectionHashes.map((hash, i) => {
            return <Section sectionHash={hash} key={hash} index={++i} />;
        });
    };

    return <div className="app-content preview-mode">{renderSections()}</div>;
};

const mapStateToProps = (state: RootState) => {
    return {
        sectionHashes: map(state.config, (section: ProjectSectionEntity) => section.hash),
    };
};

export default connect(mapStateToProps)(Preview);
