import * as React from 'react';
import { UploadListItemEntity } from '../../../types/entities/upload-list-item';
import { displayFileSize } from '../../../utils/helpers';

interface Props {
    sectionHash: string;
    item: UploadListItemEntity;
}

const UploadListRowUploading = (props: Props) => {
    const { item } = props;

    return (
        <div className="file-row uploading">
            <div className="file-row-details">
                <div className="status">{item.progress}%</div>
                <div className="name">
                    {item.file.name}
                    <div className="size">{displayFileSize(item.file.size)}</div>
                </div>
            </div>
            <div className="progress-bar">
                <div className="progress-bar__fill" style={{ width: `${item.progress}%` }} />
            </div>
        </div>
    );
};
export default UploadListRowUploading;
