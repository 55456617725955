import * as React from 'react';
import Modal from 'react-responsive-modal';
import { __ } from '../../../utils/i18n';

interface Props {
    onClose: () => void;
    onExitApp: () => void;
}

const ModalPositionsAccept = (props: Props) => {
    const { onClose, onExitApp } = props;

    return (
        <Modal
            open={true}
            onClose={onClose}
            center={true}
            classNames={{
                modal: 'uploader-custom-modal',
                closeButton: 'uploader-custom-modal-close-button',
                closeIcon: 'uploader-custom-modal-close-button-icon',
            }}
        >
            <div className="title">{__('Potwierdzenie projektu')}</div>
            <div className="content">{__('Potwierdzam projekt i kolejność plików.')}</div>
            <div className="footer">
                <button className="button secondary" onClick={onClose}>
                    {__('Wróć')}
                </button>
                <button className="button primary" onClick={onExitApp}>
                    {__('Tak, dodaj do koszyka')}
                </button>
            </div>
        </Modal>
    );
};
export default ModalPositionsAccept;
