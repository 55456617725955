import * as React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { FileEntity } from '../../../types/entities/file';
import { __ } from '../../../utils/i18n';
import Loader from '../../../components/loader';
import { getFileExtension } from '../../../utils/helpers';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type Props = {
    file: FileEntity;
    width: number;
};

interface onLoadType {
    view: number[];
}

const PdfViewer: React.FC<Props> = ({ file, width }) => {
    const [scale, setScale] = React.useState<number>(0.95);

    const _onLoad = ({ view }: onLoadType) => {
        const [x1, y1, x2, y2] = view;
        const xValues = [x1, x2].filter((v) => !!v);
        const yValues = [y1, y2].filter((v) => !!v);

        if (!xValues.length || !yValues.length) {
            return;
        }

        const [min, max] = [Math.min(...xValues), Math.max(...yValues)];

        const newScale = min / max - 0.02;

        if (newScale > 1) {
            return;
        }

        setScale(newScale);
    };

    return (
        <Document
            file={file}
            loading={<Loader size="small" title={__('Ładowanie')} />}
            error={<div className="extension-info">{getFileExtension(file.name)}</div>}
        >
            <Page
                pageNumber={1}
                width={window.screen.width > width ? width : window.screen.width - 100}
                scale={scale}
                onLoadSuccess={_onLoad}
            />
        </Document>
    );
};

export default PdfViewer;
