import * as React from 'react';
import { Tooltip } from 'react-tooltip';
import Spinner from '../../../components/spinner';
import IconWarning from '../../../components/svg-icons/warning';
import { UploadListItemEntity } from '../../../types/entities/upload-list-item';
import { displayFileSize } from '../../../utils/helpers';
import { UPLOAD_LIST_FILE_STATUS } from '../../../utils/const';
import { __ } from '../../../utils/i18n';
import IconTrash from '../../../components/svg-icons/trash';
import IconUpload from '../../../components/svg-icons/upload';

interface Props {
    item: UploadListItemEntity;
    handleRemove: (item: UploadListItemEntity) => void;
}

const UploadListRow = (props: Props) => {
    const { handleRemove, item } = props;

    const renderStatus = (item: UploadListItemEntity) => {
        switch (item.status) {
            case UPLOAD_LIST_FILE_STATUS.ERROR:
                return <IconWarning />;
            case UPLOAD_LIST_FILE_STATUS.READY:
                return (
                    <IconUpload
                        data-tooltip-content={__('Plik gotowy do wgrania')}
                        data-tooltip-id={`upload-list-row-${item.file.id}`}
                    />
                );
            case UPLOAD_LIST_FILE_STATUS.WAITING:
                return <Spinner />;
            default:
                return null;
        }
    };

    return (
        <div className="file-row">
            <div className="file-row-details">
                <div className="status">{renderStatus(item)}</div>
                <div className="name">
                    {item.file.name}
                    <div className="size">{displayFileSize(item.file.size)}</div>
                </div>
                <div className="remove">
                    <IconTrash
                        onClick={() => {
                            handleRemove(item);
                        }}
                    />
                    {__('Usuń')}
                </div>
            </div>

            {item.errors.length > 0 ? <div className="validation-info">{item.errors[0]}</div> : null}
            <Tooltip id={`upload-list-row-${item.file.id}`} place="bottom" />
        </div>
    );
};
export default UploadListRow;
