import update from 'immutability-helper';
import * as ProjectActions from '../modules/app/actions/project';
import { createSlice } from '@reduxjs/toolkit';
import { ProjectConfigState } from '../types/state';

const initialState: ProjectConfigState = {};

export const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(ProjectActions.addConfig, (state, action) => {
            return update(state, { $set: action.payload });
        });
    },
});

export default configSlice.reducer;
