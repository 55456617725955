import axios from 'axios';
import { getApi, getProjectId } from '../../../utils/helpers';
import { ProjectDataResponseEntity } from '../../../types/entities/project-response';

export const loadProject = async () => {
    const response = await axios.get<ProjectDataResponseEntity>(
        `${getApi()}/projects/${getProjectId()}?organizedFiles=1`,
    );
    return response.data;
};

export const setProjectValidationStatus = async (status: boolean) => {
    await axios.put(`${getApi()}/projects/${getProjectId()}/validation-status`, {
        validationStatus: status,
    });
};
