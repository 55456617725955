import * as React from 'react';
import { __ } from '../../../utils/i18n';
import Loader from '../../../components/loader';
import { setProjectValidationStatus } from '../services/project';
import { getBeforeClose, getRedirectOnClose, isPreviewMode } from '../../../utils/helpers';

interface Props {
    validationStatus: boolean;
}

const Exit = (props: Props) => {
    const { validationStatus } = props;

    const handleExit = async () => {
        if (!isPreviewMode()) {
            await setProjectValidationStatus(validationStatus);
        }

        const beforeClose = getBeforeClose();

        if (beforeClose) {
            await beforeClose();
        }

        const redirectOnClose = getRedirectOnClose();

        if (redirectOnClose) {
            window.location.href = redirectOnClose;
        }
    };
    React.useEffect(() => {
        handleExit();
    }, []);

    return <Loader title={__('Zamykanie projektu')} />;
};
export default Exit;
