import * as React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { FileEntity } from '../../../types/entities/file';
import FileItem from '../../files/components/file-item';

export interface SortableElementEntity {
    file: FileEntity;
    index: number;
    filesCount: number;
}

const SortableItem = SortableElement<SortableElementEntity>((props: SortableElementEntity) => {
    return <FileItem {...props} />;
});

export default SortableItem;
