import Jed from 'jed';
import { TRANSLATIONS_URL } from './config';
import jedGettextParser from 'jed-gettext-parser';

const TIMEOUT_INTERVAL = 10;
let loadStatus = false;
window.jedInstance = null;

export const stringToArrayBuffer = (str: string) => {
    const buf = new ArrayBuffer(str.length);
    const bufView = new Uint8Array(buf);

    for (let i = 0, strLen = str.length; i < strLen; i++) {
        bufView[i] = str.charCodeAt(i);
    }

    return buf;
};

// eslint-disable-next-line @typescript-eslint/ban-types
const xhrBinary = (url: string, fn: Function) => {
    // https://gist.github.com/abbotto/5a78b0094a395b860759
    const request: ActiveXObject | XMLHttpRequest = window.ActiveXObject || window.XMLHttpRequest;
    const xhr = new request('MSXML2.XMLHTTP.3.0');
    let data = '';
    let buffer = '';

    xhr.open('GET', url, true);

    if (window.Uint8Array) {
        xhr.responseType = 'arraybuffer';
    } else if ('overrideMimeType' in xhr) {
        xhr.overrideMimeType('text/plain; charset=x-user-defined');
    } else {
        xhr.setRequestHeader('Accept-Charset', 'x-user-defined');
    }

    xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
            if (this.status === 200) {
                if (window.Uint8Array || window.VBArray) {
                    buffer = window.Uint8Array ? new Uint8Array(xhr.response) : xhr.responseBody.toArray();
                    // data = String.fromCharCode.apply(null, buffer);
                    let str = '';
                    for (let i = 0, len = buffer.length; i < len; i++) {
                        str += String.fromCharCode(Number(buffer[i]));
                    }

                    data = str;
                }

                if (typeof fn !== 'undefined') {
                    fn(data);
                } else {
                    return data;
                }
            } else {
                loadStatus = true;
            }
        }

        return false;
    };

    xhr.send();
};

const getText = (key: string): string => {
    const jedInst = window.jedInstance;

    if (!jedInst) {
        return key;
    }

    return jedInst.gettext(key);
};

export const translationLoader = (lang: string) => {
    try {
        if (!lang || lang === 'pl') {
            loadStatus = true;
            return;
        }

        const file = TRANSLATIONS_URL[lang];
        const refreshToken = new Date().getTime();

        xhrBinary(
            `${window.__UPLOADER_CONFIG__.api.replace('/api/v1', '')}/${file}?version=${refreshToken}`,
            (response: string) => {
                window.jedInstance = new Jed({
                    locale_data: jedGettextParser.mo.parse(stringToArrayBuffer(response)),
                    domain: 'messages',
                });

                loadStatus = true;
            },
        );
    } catch (error) {
        console.log('Translations error', error);
    }
};

export const __ = (key: string, data: string[] | null = []) => {
    let message = getText(key);

    if (data && data.length) {
        const count = (message.match(/%s/g) || []).length;

        for (let i = 0; i <= count; i++) {
            message = message.replace(/%s/, data[i]);
        }
    }

    return message;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const whenTranslationLoaded = (callback: Function) => {
    if (!loadStatus) {
        setTimeout(() => {
            whenTranslationLoaded(callback);
        }, TIMEOUT_INTERVAL);
    } else {
        callback();
    }
};
