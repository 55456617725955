import * as React from 'react';
import { FileEntity } from '../../../types/entities/file';
import { __ } from '../../../utils/i18n';
import Spinner from '../../../components/spinner';
import { getFileExtension, sanitizeThumbPath } from '../../../utils/helpers';
import ThumbError from '../../files/components/thumb-error';
import { ProjectSectionEntity } from '../../../types/entities/project-section';
import { calcPageNumber } from '../../files/services/sorting';
import { THUMB_STATUS } from '../../files/components/types';
import FilePreview from '../../files/components/file-preview';
import { getFileThumbStatus } from '../../files/components/helpers';
import { useEffect, useState } from 'react';

interface Props {
    file: FileEntity;
    sectionConfig: ProjectSectionEntity;
    countFilesInSection: number;
}

const FileItem = (props: Props) => {
    const [thumbStatus, setThumbStatus] = useState(THUMB_STATUS.WAITING);
    const { countFilesInSection, file, sectionConfig } = props;

    const renderThumb = () => {
        switch (thumbStatus) {
            case THUMB_STATUS.AVAILABLE:
                return (
                    <div
                        className="thumb-image"
                        style={{
                            backgroundImage: `url(${sanitizeThumbPath(file.thumb as string)})`,
                        }}
                    />
                );
            case THUMB_STATUS.ERROR:
                return <ThumbError />;
            case THUMB_STATUS.PREVIEWABLE:
                return <FilePreview file={file} width={475} />;
            case THUMB_STATUS.NOT_AVAILABLE:
                return <div className="extension-info">{getFileExtension(file.name)}</div>;
            case THUMB_STATUS.WAITING:
            default:
                return <Spinner />;
        }
    };

    const handleThumbStatus = async () => {
        const thumbStatus = await getFileThumbStatus(file);
        setThumbStatus(thumbStatus);
    };

    useEffect(() => {
        handleThumbStatus();
    }, []);

    return (
        <div className="file-item">
            <div className="thumb">{renderThumb()}</div>
            <div className="name">{file.name}</div>
            <div className="footer">
                <div className="position">
                    {sectionConfig.showPageNumber ? (
                        <>
                            <span className="label">{__('Strony')}</span>
                            <span className="position-number">
                                {calcPageNumber(file.position, countFilesInSection)}
                            </span>
                        </>
                    ) : null}

                    {sectionConfig.showPosition ? (
                        <>
                            <span className="label">{__('Pozycja:')}</span>
                            <span className="position-number">{file.position}</span>
                        </>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default FileItem;
