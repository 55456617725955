import update from 'immutability-helper';
import { SelectedFilesState } from '../types/state';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FileEntity } from '../types/entities/file';

const initialState: SelectedFilesState = {};

export const selectedFilesSlice = createSlice({
    name: 'selectedFiles',
    initialState,
    reducers: {
        init: (state, action: PayloadAction<string[]>) => {
            return update(state, {
                $apply: (s: SelectedFilesState) => {
                    action.payload.forEach((item) => {
                        s[item] = [];
                    });

                    return s;
                },
            });
        },
        select: (state, action: PayloadAction<FileEntity[]>) => {
            return update(state, { [action.payload[0].section]: { $push: action.payload.map((file) => file.id) } });
        },
        unselect: (state, action: PayloadAction<FileEntity[], string>) => {
            const transform = action.payload.map((file) => file.id);
            return update(state, {
                [action.payload[0].section]: {
                    $set: state[action.payload[0].section].filter(
                        (item) => transform.findIndex((id) => id !== item) !== -1,
                    ),
                },
            });
        },
        unselectAll: (state, action: PayloadAction<string>) => {
            return update(state, { [action.payload]: { $set: [] } });
        },
    },
});

export const { init, select, unselect, unselectAll } = selectedFilesSlice.actions;
export default selectedFilesSlice.reducer;
