import * as React from 'react';
import { createInstance } from '../../../utils/uploader';
import { __ } from '../../../utils/i18n';
import { UPLOADER_MAX_RETRIES, UPLOADER_CHUNK_SIZE } from '../../../utils/config';
import ExplainImage from './explain-image';
import UploadButtonPhotosSvg from './upload-button-photos-svg';
import UploadButtonMockToggle from './upload-button-mock-toggle';
import { getFilesContainerId } from './sortable-list';
import { useEffect, useRef } from 'react';
import AddIcon from '../../../components/svg-icons/add';

interface Props {
    sectionHash: string;
    extensions: string;
    explainImage: string | undefined;
    maxSize: string | null;
    size: string | null;
    dimensions: string | null;
    enableChunks: boolean;
    filesCountInSection: number;
    dpi: number;
}

const UploadButton = (props: Props) => {
    const { dimensions, enableChunks, explainImage, extensions, filesCountInSection, maxSize, sectionHash, size, dpi } =
        props;

    const uploadButtonRef = useRef<HTMLDivElement>(null);
    const uploadFieldRef = useRef<HTMLDivElement>(null);

    const showDropdownZone = (e: DragEvent) => {
        if (e.dataTransfer && Array.isArray(e.dataTransfer.types) && e.dataTransfer.types.includes('Files')) {
            uploadFieldRef.current?.classList.add('show');
        }
    };

    const hideDropdownZone = () => {
        uploadFieldRef.current?.classList.remove('show');
    };

    useEffect(() => {
        createInstance(sectionHash, {
            browse_button: uploadButtonRef.current,
            drop_element: uploadFieldRef.current,
            max_retries: UPLOADER_MAX_RETRIES,
            chunk_size: enableChunks ? UPLOADER_CHUNK_SIZE : 0,
            filters: {
                mime_types: [{ title: __('Wybierz plik(i)'), extensions }],
                max_file_size: maxSize,
                prevent_duplicates: true,
            },
            multipart_params: {
                sectionHash: sectionHash,
            },
        });
        const container = document.getElementById(getFilesContainerId(sectionHash));
        if (container) {
            container.addEventListener('dragenter', showDropdownZone);
        }
        uploadFieldRef?.current?.addEventListener('dragleave', hideDropdownZone);
        uploadFieldRef?.current?.addEventListener('drop', hideDropdownZone);

        return () => {
            const container = document.getElementById(getFilesContainerId(sectionHash));
            if (container) {
                container.removeEventListener('dragenter', showDropdownZone);
            }
            uploadFieldRef.current?.removeEventListener('dragleave', hideDropdownZone);
            uploadFieldRef.current?.removeEventListener('drop', hideDropdownZone);
        };
    }, []);

    const renderButton = (type: 'full' | 'small') => {
        return (
            <div className="upload full-button">
                {explainImage ? (
                    <div>
                        <ExplainImage imageUrl={explainImage} />
                    </div>
                ) : null}
                <div className="upload-button" ref={uploadButtonRef}>
                    <UploadButtonPhotosSvg />
                    <div className="text-info">
                        <div className="text">{__('Upuść pliki tutaj')}</div>
                        <div className="text-small">{__('lub')}</div>
                    </div>
                    <button className="button action add-file">
                        <AddIcon /> {__('Dodaj plik')}
                    </button>
                    <div className="details">
                        <UploadButtonMockToggle sectionHash={sectionHash} />
                        {renderFileInfo(type)}
                    </div>
                </div>
            </div>
        );
    };

    const renderFileInfo = (type: 'full' | 'small') => {
        return (
            <>
                <div className="details__item details__item--formats">
                    {__('Dozwolone formaty:')} <p>{extensions}</p>
                </div>
                <div className="details__separator" />
                {type === 'full' ? (
                    <>
                        {dimensions || size ? (
                            <div className="details__item">
                                {__('Wymiary:')}
                                {dimensions && <p>{`${dimensions} px ${size ? `(${size}mm)` : null}`}</p>}
                            </div>
                        ) : null}
                        <div className="details__separator" />
                        {dpi ? (
                            <div className="details__item">
                                {__('Rozdzielczość:')} <p>{`${dpi} dpi`}</p>
                            </div>
                        ) : null}
                    </>
                ) : null}
                {type === 'small' ? (
                    <>
                        {maxSize ? (
                            <div className="details__item">
                                {__('Max, rozmiar:')} <p>{maxSize}</p>
                            </div>
                        ) : null}
                    </>
                ) : null}
            </>
        );
    };

    return (
        <>
            <div className="upload-drop-zone" ref={uploadFieldRef}>
                <span>{__('Upuść pliki tutaj')}</span>
            </div>
            {/* {filesCountInSection === 0 ? renderFullButton() : renderSmallButton()} */}
            {filesCountInSection === 0 ? renderButton('full') : renderButton('small')}
        </>
    );
};

export default UploadButton;
