import { FileEntity } from '../types/entities/file';
import { __ } from './i18n';
import {
    IMAGE_DPI_TOLERANCE,
    IMAGE_PX_DOWN_TOLERANCE,
    IMAGE_PX_UP_TOLERANCE,
    IMAGE_RATIO_TOLERANCE,
    PDF_MM_DOWN_TOLERANCE,
    PDF_MM_UP_TOLERANCE,
} from './config';
import { PDFHeader } from 'pdf-lib';
import { VersionType } from '../types/shared';

/*
|--------------------------------------------------------------------------
| Globals variables
|--------------------------------------------------------------------------
*/
export const getProjectId = (): string => {
    return window.__UPLOADER_CONFIG__.projectId;
};

export const getHost = (): string => {
    return window.__UPLOADER_CONFIG__.host;
};

export const getApi = (): string => {
    return window.__UPLOADER_CONFIG__.api;
};

export const getToken = (): string => {
    return window.__UPLOADER_CONFIG__.token;
};

export const getUserHash = (): string => {
    return window.__UPLOADER_CONFIG__.userHash;
};

export const getBeforeClose = (): { (): Promise<void> } | null => {
    if (window.__UPLOADER_CONFIG__.beforeClose) {
        return window.__UPLOADER_CONFIG__.beforeClose;
    }
    return null;
};

export const getRedirectOnClose = (): string | null => {
    if (window.__UPLOADER_CONFIG__.redirectOnClose) {
        return window.__UPLOADER_CONFIG__.redirectOnClose;
    }
    return null;
};

export const isPreviewMode = (): boolean => {
    return window.__UPLOADER_CONFIG__.previewMode || false;
};

/*
|--------------------------------------------------------------------------
| Helpers functions
|--------------------------------------------------------------------------
*/
export const isProduction = (): boolean => Boolean(import.meta.env.PROD);

export const displayFileSize = (size: number): string => {
    const kb = 1024;
    const mb = 1048576;
    const gb = 1073741824;
    let calculatedSize: number = size;
    let unit = 'B';
    if (size >= kb && size < mb) {
        calculatedSize = Math.round(size / kb);
        unit = 'KB';
    } else if (size >= mb && size < gb) {
        calculatedSize = Math.round(size / mb);
        unit = 'MB';
    } else if (size >= gb) {
        calculatedSize = Math.round(size / gb);
        unit = 'GB';
    }
    return `${calculatedSize} ${unit}`;
};

export const sortFiles = (fileA: FileEntity, fileB: FileEntity) => {
    if (fileA.position < fileB.position) {
        return -1;
    }
    if (fileA.position > fileB.position) {
        return 1;
    }
    return 0;
};

export const sortFilesByName = (fileA: FileEntity, fileB: FileEntity) => {
    return fileA.name.localeCompare(fileB.name, undefined, { numeric: true, sensitivity: 'base' });
};

export const getFileExtension = (fileName: string): string => {
    return fileName.split('.').pop() || '';
};

export const isAvilableThumbForFile = (fileExtension: string) => {
    const extension = fileExtension.toLowerCase();

    return extension === 'jpg' || extension === 'jpeg';
};

export const isPDFFile = (fileExtension: string) => {
    const extension = fileExtension.toLowerCase();

    return extension === 'pdf';
};

export const getPDFVersion = (pdfHeader: PDFHeader): VersionType => {
    return {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        major: Number(pdfHeader.major),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        minor: Number(pdfHeader.minor),
    };
};

export const sanitizeThumbPath = (path: string) => {
    /* tslint:disable */
    return encodeURI(path).replace(/'/g, '%27').replace(/\(/g, '%28').replace(/\)/g, '%29');
};
/* tslint:enable */

/*
|--------------------------------------------------------------------------
| Convert functions / Dimension valid
|--------------------------------------------------------------------------
*/
const inchPX = 25.4;
const pointToMM = 0.3527777778;

export const convertPointToMM = (point: number): number => {
    return point * pointToMM;
};

export const convertMmToPx = (mm: number, dpi: number): number => {
    return Math.floor((dpi * mm) / inchPX);
};

export const calcDpi = (px: number, mm: number): number => {
    return Math.floor((px * inchPX) / mm);
};

export const checkDimensions = (
    fileWidth: number,
    fileHeight: number,
    widthMM: number,
    heightMM: number,
    dpi: number,
): boolean | string => {
    const acceptWidthPX = convertMmToPx(widthMM, dpi);
    const acceptHeightPX = convertMmToPx(heightMM, dpi);

    // Size
    if (
        fileWidth < acceptWidthPX - IMAGE_PX_DOWN_TOLERANCE ||
        fileWidth > acceptWidthPX + IMAGE_PX_UP_TOLERANCE ||
        fileHeight < acceptHeightPX - IMAGE_PX_DOWN_TOLERANCE ||
        fileHeight > acceptHeightPX + IMAGE_PX_UP_TOLERANCE
    ) {
        return __('Plik ma nieprawidłowe wymiary') + `: ${fileWidth}x${fileHeight}`;
    }

    // DPI
    if (calcDpi(fileWidth, widthMM) < dpi - IMAGE_DPI_TOLERANCE) {
        return __('Plik ma za niskie DPI');
    }

    // Ratio
    const ratio = widthMM / heightMM;
    const fileRatio = fileWidth / fileHeight;

    if (fileRatio < ratio - IMAGE_RATIO_TOLERANCE || fileRatio > ratio + IMAGE_RATIO_TOLERANCE) {
        return __('Plik ma nieprawidłowe proporcje');
    }

    return true;
};

export const checkPDFDimensions = (
    fileWidthMM: number,
    fileHeightMM: number,
    widthMM: number,
    heightMM: number,
    index: number,
): boolean | string => {
    if (
        fileWidthMM < widthMM - PDF_MM_DOWN_TOLERANCE ||
        fileWidthMM > widthMM + PDF_MM_UP_TOLERANCE ||
        fileHeightMM < heightMM - PDF_MM_DOWN_TOLERANCE ||
        fileHeightMM > heightMM + PDF_MM_UP_TOLERANCE
    ) {
        return (
            __('Plik ma nieprawidłowe wymiary') +
            `: ${Math.floor(fileWidthMM)}[mm]x${Math.floor(fileHeightMM)}[mm] ` +
            __('Strona') +
            ` ${index + 1}`
        );
    }

    return false;
};
