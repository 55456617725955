import * as React from 'react';
import { __ } from '../../../utils/i18n';
import { isPreviewMode } from '../../../utils/helpers';

interface Props {
    errorLoadProject: boolean;
    onExit: () => void;
}

const AppBar = (props: Props) => {
    const { onExit, errorLoadProject } = props;
    return (
        <div className="app-bar">
            <p className="app-bar__title">{__('Dodawanie plików')}</p>
            <div className="app-bar__action">
                <button className="button primary" onClick={onExit}>
                    {errorLoadProject || isPreviewMode() ? __('Zamknij') : __('Zatwierdź')}
                </button>
            </div>
        </div>
    );
};

export default AppBar;
