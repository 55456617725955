/* tslint:disable */
import React from 'react';

const AddIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
        <path
            d="M12.5 8V16M8.5 12H16.5M22.5 12C22.5 17.5228 18.0228 22 12.5 22C6.97715 22 2.5 17.5228 2.5 12C2.5 6.47715 6.97715 2 12.5 2C18.0228 2 22.5 6.47715 22.5 12Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default AddIcon;
