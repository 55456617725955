export const TRANSLATIONS_URL: { [key: string]: string } = {
    us: 'app/translations/en_US.mo',
    en: 'app/translations/en_GB.mo',
    de: 'app/translations/de_DE.mo',
    nl: 'app/translations/nl_NL.mo',
    es: 'app/translations/es_ES.mo',
};

export const DEFAULT_DPI = 300;
export const IMAGE_DPI_TOLERANCE = 50;
export const IMAGE_RATIO_TOLERANCE = 0.01;

export const IMAGE_PX_UP_TOLERANCE = 10;
export const IMAGE_PX_DOWN_TOLERANCE = 10;

export const PDF_MM_UP_TOLERANCE = 2.2;
export const PDF_MM_DOWN_TOLERANCE = 2.2;

export const UPLOADER_MAX_RETRIES = 1;
export const UPLOADER_CHUNK_SIZE = '100Mb';
export const UPLOADER_DEFAULT_MAX_FILE_SIZE = '50Mb';
