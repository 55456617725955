import { FileEntity } from '../../../types/entities/file';
import { getFileExtension, isAvilableThumbForFile } from '../../../utils/helpers';
import { getThumbUrl } from '../services/files';
import store from '../../../utils/store';
import * as FilesActions from '../actions/files';
import { THUMB_STATUS } from './types';

export const isFilePreviewable = (extension: string) => ['pdf', 'svg'].includes(extension);

export const getFileThumbStatus = async (file: FileEntity) => {
    if (isAvilableThumbForFile(getFileExtension(file.name))) {
        if (!file.thumb) {
            return getThumbUrl(file)
                .then((response) => {
                    store.dispatch(FilesActions.addThumb(file, response.data.thumb));

                    return THUMB_STATUS.AVAILABLE;
                })
                .catch(() => {
                    return THUMB_STATUS.ERROR;
                });
        } else {
            return THUMB_STATUS.AVAILABLE;
        }
    } else if (isFilePreviewable(getFileExtension(file.name))) {
        return THUMB_STATUS.PREVIEWABLE;
    }

    return THUMB_STATUS.NOT_AVAILABLE;
};
