import axios from 'axios';
import store from '../../../utils/store';
import { FileEntity } from '../../../types/entities/file';
import { getApi, getProjectId } from '../../../utils/helpers';
import { SortingEntity } from '../../../types/shared';

export const calcPositions = (files: FileEntity[]): SortingEntity[] => {
    return files.map((file, i) => {
        return {
            fileId: Number(file.id),
            position: ++i,
        };
    });
};

export const saveSorting = async (sectionHash: string) => {
    const data = store.getState().data[sectionHash] as FileEntity[];

    if (data.length === 0) {
        return;
    }

    const positions: { [key: number]: number } = {};
    data.forEach((file) => {
        positions[file.id] = file.position;
    });

    const url = `${getApi()}/projects/${getProjectId()}/sections/${sectionHash}/files/positions`;
    await axios.put(url, { positions });
};

export const prepareSortingAfterClickMove = (
    sectionHash: string,
    fileId: number,
    direction: string,
): SortingEntity[] => {
    const data = JSON.parse(JSON.stringify(store.getState().data[sectionHash])) as FileEntity[];

    const clickedFileIndex = data.findIndex((file) => file.id === fileId);
    const startIndex = direction === 'left' ? clickedFileIndex - 1 : clickedFileIndex;

    const cutFiles = data.splice(startIndex, 2);

    cutFiles.reverse();
    data.splice(startIndex, 0, ...cutFiles);

    return data.map((file, i) => {
        return {
            fileId: Number(file.id),
            position: ++i,
        };
    });
};

export const getNextPosition = (sectionHash: string) => {
    const data = store.getState().data[sectionHash] as FileEntity[];

    if (data.length === 0) {
        return 1;
    }

    const max = Math.max(...data.map((file) => file.position));

    return max + 1;
};

export const calcPageNumber = (filePosition: number, countFilesInSection: number) => {
    let display;
    const num = filePosition * 2;

    if (filePosition === 1) {
        display = filePosition;
    } else if (filePosition === countFilesInSection) {
        display = countFilesInSection * 2 - 2;
    } else {
        display = `${num - 2}-${num - 1}`;
    }

    return display;
};
