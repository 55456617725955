import { combineReducers } from 'redux';
import AppReducer from '../reducers/app-reducer';
import InfoReducer from '../reducers/info-reducer';
import ConfigReducer from '../reducers/config-reducer';
import DataReducer from '../reducers/data-reducer';
import UploadListReducer from '../reducers/upload-list-reducer';
import SelectedFilesReducer from '../reducers/selected-files-reducer';
import SectionValidationReducer from '../reducers/section-validation-reducer';
import { configureStore } from '@reduxjs/toolkit';

const reducer = combineReducers({
    app: AppReducer,
    info: InfoReducer,
    config: ConfigReducer,
    data: DataReducer,
    uploadList: UploadListReducer,
    selectedFiles: SelectedFilesReducer,
    sectionValidation: SectionValidationReducer,
});

const store = configureStore({
    reducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
