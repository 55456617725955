import * as React from 'react';
import axios from 'axios';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import App from './modules/app/components/app';
import { translationLoader, whenTranslationLoaded } from './utils/i18n';
import { scriptLoader, whenScriptLoaded } from './utils/scripts-loader';
import { getHost, getToken } from './utils/helpers';
import store from './utils/store';
import 'react-responsive-modal/styles.css';

import './assets/styles/style.scss';
import { createRoot } from 'react-dom/client';

/*
|--------------------------------------------------------------------------
| Translations
|--------------------------------------------------------------------------
*/
console.log('translationLoader');
translationLoader(window.__UPLOADER_CONFIG__.language || 'pl');

/*
|--------------------------------------------------------------------------
| Load needed scripts
|--------------------------------------------------------------------------
*/
console.log('scriptLoader');
scriptLoader([
    {
        src: `${getHost()}/plupload.min.js`,
    },
]);

/*
|--------------------------------------------------------------------------
| Set Api Token
|--------------------------------------------------------------------------
*/
// axios.defaults.headers.common['Authorization'] = isProduction() ? `Hash ${getToken()}` : `Bearer ${getToken()}`;
axios.defaults.headers.common['Authorization'] = `Hash ${getToken()}`;

/*
|--------------------------------------------------------------------------
| Create and run App
|--------------------------------------------------------------------------
*/
const Uploader = () => {
    return (
        <Provider store={store}>
            <>
                <ToastContainer />
                <App />
            </>
        </Provider>
    );
};

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

whenTranslationLoaded(() => {
    whenScriptLoaded(() => {
        root.render(<Uploader />);
    });
});
