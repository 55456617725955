import update from 'immutability-helper';
import { SectionValidationState } from '../types/state';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: SectionValidationState = {};

export const sectionValidationSlice = createSlice({
    name: 'sectionValidation',
    initialState,
    reducers: {
        init: (state, action: PayloadAction<string[]>) => {
            return update(state, {
                $apply: (s: SectionValidationState) => {
                    action.payload.forEach((item) => {
                        s[item] = [];
                    });

                    return s;
                },
            });
        },

        add: {
            reducer(state, action: PayloadAction<string, string, string[]>) {
                return update(state, { [action.payload]: { $set: action.meta } });
            },
            prepare(sectionHash: string, errors: string[]) {
                return {
                    payload: sectionHash,
                    meta: errors,
                };
            },
        },

        clear: (state, action: PayloadAction<string>) => {
            return update(state, { [action.payload]: { $set: [] } });
        },
    },
});

export const { init, add, clear } = sectionValidationSlice.actions;
export default sectionValidationSlice.reducer;
