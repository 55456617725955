import * as React from 'react';
import { connect } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { FileEntity } from '../../../types/entities/file';
import { __ } from '../../../utils/i18n';
import store, { RootState } from '../../../utils/store';
import * as FilesActions from '../actions/files';
import { calcPositions, saveSorting } from '../services/sorting';
import { deleteFile } from '../services/files';
import * as FilesSortingActions from '../actions/sorting';
import Spinner from '../../../components/spinner';
import FileModalDelete from './modal-delete';
import * as SelectedFiles from '../../../reducers/selected-files-reducer';

import ThumbError from './thumb-error';
import { getFileExtension, sanitizeThumbPath } from '../../../utils/helpers';
import { WaitingToDeleteContext } from '../../sections/components/waiting-to-delete-context';
import { WaitingToDeleteContextEntity } from '../../../types/shared';
import { ProjectSectionEntity } from '../../../types/entities/project-section';
import { validateSection } from '../../sections/services/validation';
import IconWarning from '../../../components/svg-icons/warning';
import IconCheckbox from '../../../components/svg-icons/checkbox';
import IconCheckboxSelected from '../../../components/svg-icons/checkbox-selected';
import IconTrash from '../../../components/svg-icons/trash';
import { getFileThumbStatus } from './helpers';
import { THUMB_STATUS } from './types';
import FilePreview from './file-preview';

import '../assets/style.scss';
import { SortableElementEntity } from '../../sections/components/sortable-item';
import { useEffect, useState } from 'react';
import { Portal } from '../../../components/portal';
import { IconPreview } from '../../../components/svg-icons/preview';
import { usePortal } from '../../../components/portal/usePortal';
import { UploaderPdfPreview } from './uploderPdfPreview/uploader-pdf-preview';

interface PropsConnected extends SortableElementEntity {
    isSelected: boolean;
    filePosition: number;
    countFilesInSection: number;
    waitingToDeleteContext?: WaitingToDeleteContextEntity;
    sectionConfig: ProjectSectionEntity;
}

const FileItem = (props: PropsConnected) => {
    const { open, handleClosePortal, handleOpenPortal, portalRef } = usePortal();
    const [state, setState] = useState({
        showModal: false,
        deleteInProgress: false,
        thumbStatus: THUMB_STATUS.WAITING,
    });
    const { file, filePosition, filesCount, isSelected, waitingToDeleteContext } = props;
    // const isFirst = index === 0;
    // const isLast = index === filesCount - 1;
    const hasErrors = file.errors && file.errors.length > 0;

    const handleFileThumbStatus = async () => {
        const thumbStatus = await getFileThumbStatus(file);
        checkPosition();
        setState({ ...state, thumbStatus });
    };

    useEffect(() => {
        handleFileThumbStatus();
    }, []);

    useEffect(() => {
        if (state.deleteInProgress) {
            (async () => {
                try {
                    await deleteFile(file);

                    if (waitingToDeleteContext && waitingToDeleteContext.afterDelete) {
                        waitingToDeleteContext.afterDelete(file);
                    }

                    store.dispatch(FilesActions.remove(file));
                    store.dispatch(
                        FilesSortingActions.save(file.section, calcPositions(store.getState().data[file.section])),
                    );

                    validateSection(file.section);
                    saveSorting(file.section);
                } catch (error) {
                    console.log(error);
                }
            })();
        }
        if (!state.deleteInProgress && waitingToDeleteContext && waitingToDeleteContext.idsToDelete !== null) {
            const find = waitingToDeleteContext.idsToDelete.find((item) => item === Number(file.id));

            if (find) {
                handleRemoveFile();
            }
        }
    }, [state]);

    const checkPosition = () => {
        if (filePosition > filesCount) {
            store.dispatch(FilesActions.update({ ...file, position: filesCount }));
            store.dispatch(FilesSortingActions.save(file.section, calcPositions(store.getState().data[file.section])));

            validateSection(file.section);
            saveSorting(file.section);
        }
    };
    const markAsSelected = () => {
        store.dispatch(SelectedFiles.select([file]));
    };

    const markAsUnselected = () => {
        store.dispatch(SelectedFiles.unselect([file]));
    };

    // const handleMove = (direction: string) => {
    //     const { id, section } = file;
    //     const newSorting = prepareSortingAfterClickMove(section, id, direction);

    //     store.dispatch(FilesSortingActions.save(section, newSorting));
    //     saveSorting(section);
    // };

    const handleRemoveFile = () => {
        setState({ ...state, deleteInProgress: true, showModal: false });
    };

    const renderThumb = () => {
        switch (state.thumbStatus) {
            case THUMB_STATUS.AVAILABLE:
                return (
                    <div className="thumb-image">
                        <img
                            src={sanitizeThumbPath(file.thumb as string)}
                            className="img-responsive"
                            draggable={false}
                            alt=""
                        />
                    </div>
                );
            case THUMB_STATUS.ERROR:
                return <ThumbError />;

            case THUMB_STATUS.PREVIEWABLE: {
                return <FilePreview file={file} width={275} />;
            }
            case THUMB_STATUS.NOT_AVAILABLE:
                return <div className="extension-info">{getFileExtension(file.name)}</div>;

            case THUMB_STATUS.WAITING:
            default:
                return <Spinner />;
        }
    };

    return (
        <div className="file-item">
            <div className="file-item--thumb">
                <div className="file-item__action-buttons">
                    <div className="preview-button file-item__action-buttons-item" onClick={handleOpenPortal}>
                        <IconPreview />
                        {__('Podgląd')}
                    </div>
                    <div className="file-item__action-buttons-divider" />
                    <div
                        className="delete-button file-item__action-buttons-item"
                        onClick={() => {
                            setState({ ...state, showModal: true });
                        }}
                    >
                        <IconTrash width="17" height="16" />
                        {__('Usuń')}
                    </div>
                </div>

                {state.deleteInProgress ? (
                    <div className="layer delete">
                        <div className="overlay" />
                        <Spinner />
                    </div>
                ) : null}

                {hasErrors ? (
                    <div className="layer warning">
                        <div className="overlay" />
                        <IconWarning />
                        <div className="errors-list">
                            {file.errors &&
                                file.errors.map((error) => (
                                    <div className="error" key={`${file.section}_${file.id}_${error}`}>
                                        {error}
                                    </div>
                                ))}
                        </div>
                    </div>
                ) : null}

                <div className="thumb">{renderThumb()}</div>
                {state.showModal ? (
                    <FileModalDelete
                        removeHandler={handleRemoveFile}
                        onClose={() => setState({ ...state, showModal: false })}
                        fileName={file.name}
                    />
                ) : null}
                <Tooltip place="bottom" id={`file-move-tooltip-${file.id}`} />
                <Tooltip place="bottom" float={true} id={`file_${file.section}_${file.id}`} />
                <Portal isOpen={open} container={'.files-container'}>
                    <UploaderPdfPreview
                        onClose={handleClosePortal}
                        portalRef={portalRef}
                        url={file.url}
                        fileName={file.name}
                    />
                </Portal>
            </div>
            <div className="file-item--check-container">
                <div className="footer">
                    {/* <div className="move left">
                    {!isFirst && sectionConfig.dragAndDrop ? (
                        <IconLeftArrow
                            onClick={() => {
                                handleMove('left');
                            }}
                            data-tip={__('Przesuń w lewo')}
                            data-for={`file-move-tooltip-${file.id}`}
                        />
                    ) : null}
                </div> */}

                    <div className="position">
                        {isSelected ? (
                            <IconCheckboxSelected onClick={markAsUnselected} />
                        ) : (
                            <IconCheckbox onClick={markAsSelected} />
                        )}

                        {/* {sectionConfig.showPageNumber ? (
                        <>
                            <div className="label">{__('Strony')}</div>
                            <div className="position-number">{calcPageNumber(filePosition, countFilesInSection)}</div>
                        </>
                    ) : null} */}

                        {/* {sectionConfig.showPosition ? (
                        <>
                            <div className="label">{__('Pozycja:')}</div>
                            <div className="position-number">{filePosition}</div>
                        </>
                    ) : null} */}
                    </div>

                    {/* <div className="move right">
                    {!isLast && sectionConfig.dragAndDrop ? (
                        <IconRightArrow
                            onClick={() => {
                                handleMove('right');
                            }}
                            data-tooltip-content={__('Przesuń w prawo')}
                            data-tooltip-id={`file-move-tooltip-${file.id}`}
                        />
                    ) : null}
                </div> */}
                </div>
                <div className="name" title={file.name}>
                    {file.name}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState, props: SortableElementEntity) => {
    const { section } = props.file;
    const file = (state.data[section] as FileEntity[]).find((item) => item.id === props.file.id);

    return {
        sectionConfig: state.config[section],
        filePosition: file ? file.position : 0,
        isSelected: (state.selectedFiles[section] as number[]).some((item) => item === props.file.id),
        countFilesInSection: (state.data[section] as FileEntity[]).length,
    };
};

export default connect(mapStateToProps)((props: PropsConnected) => {
    return (
        <WaitingToDeleteContext.Consumer>
            {(waitingToDeleteContext) => <FileItem {...props} waitingToDeleteContext={waitingToDeleteContext} />}
        </WaitingToDeleteContext.Consumer>
    );
});
