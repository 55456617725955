import * as React from 'react';
import { getFileExtension } from '../../../utils/helpers';
import PdfViewer from './pdf-viewer';
import { FileEntity } from '../../../types/entities/file';

type Props = {
    file: FileEntity;
    width: number;
};

const FilePreview: React.FC<Props> = ({ file, width }) => {
    const fileExt = getFileExtension(file.name);

    if (fileExt === 'svg') {
        return (
            <div className="thumb-image">
                <img src={file.url} alt="" />
            </div>
        );
    }

    if (fileExt === 'pdf' && file.url) {
        return (
            <div className="thumb-image">
                <PdfViewer file={file} width={width} />
            </div>
        );
    }

    return <div className="extension-info">{getFileExtension(file.name)}</div>;
};

export default FilePreview;
