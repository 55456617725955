import axios from 'axios';
import { getApi, getProjectId } from '../../../utils/helpers';
import { loadProject } from '../../app/services/project';
import store from '../../../utils/store';
import * as ProjectActions from '../../app/actions/project';

export const toggleMockStatus = async (sectionHash: string, status: boolean) => {
    const url = `${getApi()}/projects/${getProjectId()}/mock-choice-status/${sectionHash}`;
    await axios.put(url, { mockChoiceStatus: status });

    const project = await loadProject();
    store.dispatch(ProjectActions.addConfig(project.sections));
};
