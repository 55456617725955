import * as React from 'react';
import { connect } from 'react-redux';
import Toggle from 'react-toggle';
import { __ } from '../../../utils/i18n';
import { ProjectSectionEntity } from '../../../types/entities/project-section';
import Spinner from '../../../components/spinner';
import { toggleMockStatus } from '../services/mock';
import ErrorHandler from '../../../utils/error-handler';
import { useState } from 'react';
import { RootState } from '../../../utils/store';

interface Props {
    sectionHash: string;
}

interface PropsConnected extends Props {
    mockStatus: boolean;
    mockChoiceAvailable: boolean;
    uploadFilesCount: number;
}

const UploadButtonMockToggle = (props: Props & PropsConnected) => {
    const [progressMockChange, setProgressMockChange] = useState(false);
    const { sectionHash, mockChoiceAvailable, mockStatus, uploadFilesCount } = props;

    const handleChangeMock = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const status = e.target.checked;
        setProgressMockChange(true);

        try {
            await toggleMockStatus(sectionHash, status);
        } catch (error) {
            new ErrorHandler().show(__('Nie udało się zmienć trybu'));
        }

        setProgressMockChange(false);
    };

    return (
        <>
            {mockChoiceAvailable ? (
                <div className="mock-toggle">
                    {progressMockChange ? (
                        <div className="toogle-in-progress">
                            <Spinner /> <div className="label">{__('Zmieniam tryb...')}</div>
                        </div>
                    ) : (
                        <label>
                            <Toggle checked={mockStatus} onChange={handleChangeMock} disabled={uploadFilesCount > 0} />
                            <span className="text-label">{__('Używam makiet')}</span>
                        </label>
                    )}
                </div>
            ) : null}
        </>
    );
};
const mapStateToProps = (state: RootState, props: Props) => {
    const section = state.config[props.sectionHash] as ProjectSectionEntity;
    return {
        mockStatus: section?.mock,
        mockChoiceAvailable: section?.mockChoiceAvailable,
        uploadFilesCount: Object.keys(state.uploadList[props.sectionHash]).length,
    };
};

export default connect(mapStateToProps)(UploadButtonMockToggle);
