/* tslint:disable */
import React from 'react';

const ThumbError = () => (
    <svg version="1.1" id="Layer_1" viewBox="-49 141 512 512" className="thumb-error-icon">
        <path
            d="M135.682,485.771c3.493-2.169,7.411-3.254,11.328-3.254c3.917,0,7.834,1.084,11.329,3.253l28.382,17.614
	c6.139,3.813,13.148,5.827,20.27,5.827c0.003,0,0.006,0,0.01,0c7.128,0,14.141-2.015,20.281-5.827l28.382-17.614
	c6.988-4.336,15.668-4.336,22.656,0l28.381,17.614c12.508,7.765,28.05,7.765,40.562,0l41.354-25.667
	c4.144-2.572,6.696-7.021,6.828-11.898c0.003-0.097,0.009-0.188,0.015-0.281l0.005-220.663l-0.026-0.341
	c-0.06-2.196-0.936-4.248-2.465-5.779l-91.689-91.688c-1.635-1.663-3.784-2.567-6.075-2.567H40.284
	c-11.991,0-21.747,9.756-21.747,21.748l0.005,299.288c0.007,0.108,0.012,0.195,0.014,0.283c0.132,4.878,2.686,9.326,6.831,11.899
	l41.353,25.668c12.511,7.763,28.052,7.761,40.561-0.002L135.682,485.771z M303.691,173.485l62.785,62.784h-58.022
	c-2.626,0-4.763-2.137-4.763-4.763V173.485z M75.693,488.964l-40.175-24.937l-0.005-297.779c0-2.631,2.14-4.772,4.771-4.772h246.433
	v70.032c0,11.986,9.752,21.738,21.738,21.738h70.032l-0.005,210.781l-40.174,24.934c-3.493,2.169-7.411,3.253-11.328,3.254
	c-3.917,0-7.833-1.084-11.327-3.251l-28.383-17.617c-12.509-7.762-28.052-7.762-40.561,0.001l-28.382,17.615
	c-6.988,4.334-15.668,4.335-22.655,0.001l-28.382-17.616c-12.507-7.765-28.049-7.765-40.561,0l-28.381,17.615
	C91.36,493.297,82.679,493.298,75.693,488.964z"
        />
        <path
            d="M18.535,627.752c0,11.992,9.755,21.748,21.747,21.748h333.434c11.991,0,21.747-9.757,21.747-21.749l-0.009-91.033
	l0.005-0.062c-0.039-5.212-2.883-10.013-7.442-12.548c-4.592-2.552-10.215-2.417-14.676,0.352l-35.44,21.994
	c-6.737,4.18-15.106,4.181-21.84,0.001l-29.198-18.12c-12.258-7.611-27.488-7.611-39.744,0l-29.198,18.119
	c-6.734,4.18-15.104,4.179-21.841,0.002l-29.198-18.121c-12.259-7.609-27.488-7.608-39.746,0l-29.195,18.119
	c-6.74,4.181-15.109,4.182-21.842,0L40.659,524.46c-4.462-2.771-10.086-2.906-14.676-0.352c-4.558,2.535-7.401,7.331-7.443,12.539
	l0.004,0.209c0,0.065-0.002,0.129-0.005,0.192L18.535,627.752z M35.517,541.246l31.63,19.633c12.26,7.606,27.49,7.605,39.745-0.001
	l29.195-18.121c6.734-4.181,15.103-4.179,21.841-0.001l29.197,18.123c6.02,3.734,12.892,5.709,19.873,5.709
	c6.982,0,13.854-1.975,19.873-5.71l29.195-18.121c6.738-4.18,15.108-4.181,21.843,0l29.196,18.122
	c12.26,7.606,27.489,7.606,39.745,0l31.63-19.633l0.004,86.506c0.007,1.257-0.485,2.46-1.387,3.366
	c-0.902,0.906-2.103,1.406-3.381,1.406H40.284c-2.63,0-4.771-2.141-4.771-4.771L35.517,541.246z"
        />
    </svg>
);

export default ThumbError;
