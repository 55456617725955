import * as React from 'react';
import { __ } from '../../../utils/i18n';

interface Props {
    imageUrl: string;
}

const ExplainImage = (props: Props) => {
    const { imageUrl } = props;

    return (
        <div className="file-item explain-image">
            <div className="thumb">
                <img src={imageUrl} className="explain-image" alt="" />
            </div>
            <div className="description">{__('Umiejscowienie wgrywanego pliku')}</div>
        </div>
    );
};
export default ExplainImage;
