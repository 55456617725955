import update from 'immutability-helper';
import { AppState } from '../types/state';

import * as SectionUploadActions from '../modules/sections/actions/upload';
import { createSlice } from '@reduxjs/toolkit';

const initialState: AppState = {
    completeLoadProject: false,
    errorLoadProject: false,
    sectionWithUploadProgress: [],
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        completeLoadProject: (state) => {
            state.completeLoadProject = true;
        },
        errorLoadProject: (state) => {
            state.completeLoadProject = false;
            state.errorLoadProject = true;
        },
    },
    extraReducers(builder) {
        builder.addCase(SectionUploadActions.start, (state, action) => {
            return update(state, {
                sectionWithUploadProgress: {
                    $push: [action.payload],
                },
            });
        });
        builder.addCase(SectionUploadActions.stop, (state, action) => {
            return update(state, {
                sectionWithUploadProgress: {
                    $set: state.sectionWithUploadProgress.filter((item) => item !== action.payload),
                },
            });
        });
    },
});

export const { completeLoadProject, errorLoadProject } = appSlice.actions;
export default appSlice.reducer;
