import * as React from 'react';
import Modal from 'react-responsive-modal';
import { __ } from '../../../utils/i18n';

interface Props {
    onClose: () => void;
    onExitApp: () => void;
}

const ModalAcceptProject = (props: Props) => {
    const { onClose, onExitApp } = props;

    return (
        <Modal
            open={true}
            onClose={onClose}
            center={true}
            classNames={{
                modal: 'uploader-custom-modal',
                closeButton: 'uploader-custom-modal-close-button',
                closeIcon: 'uploader-custom-modal-close-button-icon',
            }}
        >
            <div className="title">{__('Zamykanie projektu')}</div>
            <div className="content">
                {__(
                    'Niestety, ale Twój projekt nie jest poprawnie uzupełniony, przez co nie możesz go jeszcze zamówić.',
                )}
            </div>
            <div className="footer">
                <button className="button secondary" onClick={onExitApp}>
                    {__('Zamknij projekt')}
                </button>
                <button className="button primary" onClick={onClose}>
                    {__('Wróć do edycji projektu')}
                </button>
            </div>
        </Modal>
    );
};
export default ModalAcceptProject;
