import React from 'react';
import ReactDOM from 'react-dom';

interface PortalProps {
    isOpen: boolean;
    children: React.ReactElement;
    container: string;
}

export const Portal = ({ isOpen, children, container }: PortalProps) => {
    const DOM_ELEMENT = document.querySelector(container);
    const [domReady, setDomReady] = React.useState(false);

    React.useEffect(() => {
        setDomReady(true);
    }, [domReady]);

    if (!isOpen || !DOM_ELEMENT) return null;

    return ReactDOM.createPortal(children, DOM_ELEMENT);
};
