import update from 'immutability-helper';
import { UploadListState } from '../types/state';
import { UPLOAD_LIST_FILE_STATUS } from '../utils/const';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UploadListItemEntity } from '../types/entities/upload-list-item';

const initialState: UploadListState = {};

export const uploadListSlice = createSlice({
    name: 'uploadList',
    initialState,
    reducers: {
        init: (state, action: PayloadAction<string[]>) => {
            return update(state, {
                $apply: (s: UploadListState) => {
                    action.payload.forEach((item) => {
                        s[item] = {};
                    });

                    return s;
                },
            });
        },
        add: {
            reducer(state, action: PayloadAction<{ [key: string]: UploadListItemEntity }, string, string>) {
                return update(state, { [action.meta]: { $merge: action.payload } });
            },
            prepare(sectionHash: string, files: UploadListItemEntity[]) {
                const transform: { [key: string]: UploadListItemEntity } = {};
                files.forEach((item) => (transform[item.file.id] = item));

                return {
                    payload: transform,
                    meta: sectionHash,
                };
            },
        },
        addError: {
            reducer(state, action: PayloadAction<{ sectionHash: string; fileId: string; error: string }, string>) {
                const { sectionHash, fileId, error } = action.payload;

                return update(state, {
                    [sectionHash]: {
                        [fileId]: {
                            errors: { $push: [error] },
                            status: { $set: UPLOAD_LIST_FILE_STATUS.ERROR },
                        },
                    },
                });
            },
            prepare(sectionHash: string, fileId: string, error: string) {
                return {
                    payload: {
                        sectionHash,
                        fileId,
                        error,
                    },
                };
            },
        },
        remove: {
            reducer(state, action: PayloadAction<string, string, string>) {
                return update(state, { [action.payload]: { $unset: [action.meta] } });
            },
            prepare(sectionHash: string, fileId: string) {
                return {
                    payload: sectionHash,
                    meta: fileId,
                };
            },
        },
        setFileStatus: {
            reducer(
                state,
                action: PayloadAction<{ sectionHash: string; fileId: string; status: UPLOAD_LIST_FILE_STATUS }, string>,
            ) {
                const { sectionHash, fileId, status } = action.payload;
                return update(state, { [sectionHash]: { [fileId]: { status: { $set: status } } } });
            },
            prepare(sectionHash: string, fileId: string, status: UPLOAD_LIST_FILE_STATUS) {
                return {
                    payload: {
                        sectionHash,
                        fileId,
                        status,
                    },
                };
            },
        },
        setUploadProgress: {
            reducer(state, action: PayloadAction<{ sectionHash: string; fileId: string; progress: number }, string>) {
                const { sectionHash, fileId, progress } = action.payload;
                return update(state, { [sectionHash]: { [fileId]: { progress: { $set: progress } } } });
            },
            prepare(sectionHash: string, fileId: string, progress: number) {
                return {
                    payload: {
                        sectionHash,
                        fileId,
                        progress,
                    },
                };
            },
        },
    },
});

export const { add, addError, init, remove, setFileStatus, setUploadProgress } = uploadListSlice.actions;
export default uploadListSlice.reducer;
