import { PlUploadFileEntity } from '../../../types/entities/plupload-file';
import { __ } from '../../../utils/i18n';
import { PDFDocument } from 'pdf-lib';
import { convertPointToMM, getPDFVersion } from '../../../utils/helpers';
import { PDFInfo } from '../../../types/shared';
import { checkIfPDFProblematic } from './validation';

export const getUploadFileDimensions = (file: PlUploadFileEntity): Promise<{ width: number; height: number }> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = async () => {
            try {
                const view = new DataView(reader.result as ArrayBuffer);

                let offset = 0;
                let header = view.getUint16(offset, false);

                if (header !== 0xffd8) {
                    return reject({
                        file,
                        msg: __('Plik zawiera błędy'),
                    });
                }
                offset += 2;

                for (;;) {
                    header = view.getUint16(offset, false);
                    offset += 2;
                    const length = view.getUint16(offset, false);

                    if (header === 0xffc0) {
                        break;
                    }
                    offset += length;
                }

                const height = view.getUint16(offset + 3);
                const width = view.getUint16(offset + 5);

                return resolve({
                    height,
                    width,
                });
            } catch (error) {
                const image = new window.moxie.image.Image();
                image.onload = () => {
                    return resolve({
                        height: image.height,
                        width: image.width,
                    });
                };
                image.onerror = () => {
                    return reject({
                        file,
                        msg: __('Plik zawiera błędy'),
                    });
                };
                image.load(file.getSource());
            }
        };

        reader.readAsArrayBuffer(file.getNative());
    });
};

export const getPDFFileInfo = (file: PlUploadFileEntity): Promise<PDFInfo> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = async () => {
            try {
                const view = new DataView(reader.result as ArrayBuffer);
                const pdfDoc = await PDFDocument.load(view.buffer as ArrayBuffer);

                // console.log('-> pdfDoc: ', pdfDoc);
                const pages = pdfDoc.getPages();

                const info: PDFInfo = {
                    pagesCount: pdfDoc.getPageCount(),
                    sizes: [],
                    version: getPDFVersion(pdfDoc.catalog.context.header),
                    problematic: checkIfPDFProblematic(pages),
                };
                // console.log('pages', pages);

                // console.log('--> pages: ', pages);
                // console.log('-> info: ', info);

                pages.map((page) => {
                    info.sizes.push({
                        width: convertPointToMM(page.getWidth()),
                        height: convertPointToMM(page.getHeight()),
                    });
                });

                resolve(info);
            } catch (error) {
                reject(error);
            }
        };

        reader.readAsArrayBuffer(file.getNative());
    });
};
