/* tslint:disable */
import React from 'react';

const CheckAllIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
        <g clipPath="url(#clip0_3119_85106)">
            <path
                d="M6.2513 9.99996L8.7513 12.5L13.7513 7.49996M18.3346 9.99996C18.3346 14.6023 14.6037 18.3333 10.0013 18.3333C5.39893 18.3333 1.66797 14.6023 1.66797 9.99996C1.66797 5.39759 5.39893 1.66663 10.0013 1.66663C14.6037 1.66663 18.3346 5.39759 18.3346 9.99996Z"
                stroke="#20CBB9"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_3119_85106">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default CheckAllIcon;
