import React, { SVGProps } from 'react';

const UploadButtonPhotosSvg = (props: SVGProps<SVGSVGElement>) => (
    <svg width="72" height="72" viewBox="0 0 72 72" fill="none" {...props}>
        <path
            d="M39 21L35.6534 14.3067C34.6902 12.3804 34.2086 11.4172 33.4901 10.7135C32.8547 10.0912 32.089 9.61792 31.2482 9.32793C30.2975 9 29.2206 9 27.0669 9H15.6C12.2397 9 10.5595 9 9.27606 9.65396C8.14708 10.2292 7.2292 11.1471 6.65396 12.2761C6 13.5595 6 15.2397 6 18.6V21M6 21H51.6C56.6405 21 59.1607 21 61.0859 21.9809C62.7794 22.8438 64.1562 24.2206 65.0191 25.9141C66 27.8393 66 30.3595 66 35.4V48.6C66 53.6405 66 56.1607 65.0191 58.0859C64.1562 59.7794 62.7794 61.1562 61.0859 62.0191C59.1607 63 56.6405 63 51.6 63H20.4C15.3595 63 12.8393 63 10.9141 62.0191C9.22063 61.1562 7.8438 59.7794 6.98094 58.0859C6 56.1607 6 53.6405 6 48.6V21ZM36 51V33M27 42H45"
            stroke="#525252"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default UploadButtonPhotosSvg;
