/* tslint:disable */
import React from 'react';

const IconWarning = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => (
    <svg viewBox="0 0 512 512" {...props} className="icon-warning">
        <path d="M255.6 328.2c-10.9 0-20 9.1-20 20s9.1 20 20 20c10.5 0 20-9.1 19.5-19.5.5-11.5-8.6-20.5-19.5-20.5zm0 0" />
        <path d="M440.7 399c12.6-21.7 12.6-47.5.2-69.1L315.6 113c-12.4-21.8-34.8-34.8-59.9-34.8s-47.5 13-59.9 34.7L70.3 330.1c-12.5 21.8-12.4 47.8.2 69.5 12.5 21.4 34.8 34.3 59.8 34.3h250.2c25.1 0 47.6-13 60.2-34.9zm-27.2-15.6c-7 12-19.3 19.1-33 19.1H130.2c-13.6 0-25.8-7-32.6-18.7-6.9-11.9-7-26.2-.1-38.2l125.4-217.1c6.8-11.9 19-19 32.7-19 13.7 0 25.9 7.1 32.7 19l125.4 217.1c6.8 11.8 6.7 25.8-.2 37.8zm0 0" />
        <path d="M250.6 187.8c-9.5 2.7-15.4 11.4-15.4 21.8.5 6.3.9 12.7 1.4 19 1.4 24.1 2.7 47.7 4.1 71.8.5 8.2 6.8 14.1 15 14.1s14.6-6.3 15-14.6c0-5 0-9.5.5-14.6.9-15.4 1.8-30.9 2.7-46.3.5-10 1.4-20 1.8-30 0-3.6-.5-6.8-1.8-10-4.2-8.9-13.8-13.5-23.3-11.2zm0 0" />
    </svg>
);

export default IconWarning;
