import store from '../../../utils/store';
import { ProjectSectionEntity } from '../../../types/entities/project-section';
import { FileEntity } from '../../../types/entities/file';
import { __ } from '../../../utils/i18n';
import * as SectionValidationActions from '../../../reducers/section-validation-reducer';

export const validateSection = (sectionHash: string) => {
    const config = store.getState().config[sectionHash] as ProjectSectionEntity;
    const data = store.getState().data[sectionHash] as FileEntity[];
    const { min, max } = config.files.limit;
    const errors: string[] = [];

    if (data.length > max) {
        errors.push(__('Przekroczony limit plików'));
    }

    if (data.length < min) {
        errors.push(__('Za mała liczba plików'));
    }

    data.forEach((file) => {
        if (file.errors && file.errors.length > 0) {
            errors.push(__('Pliki w sekcji zawierają błędy'));
        }
    });

    if (errors.length > 0) {
        store.dispatch(SectionValidationActions.add(sectionHash, errors));
    } else {
        store.dispatch(SectionValidationActions.clear(sectionHash));
    }
};
