import * as React from 'react';
import { connect } from 'react-redux';
import map from 'lodash-es/map';
import { useResizeDetector } from 'react-resize-detector';

import { UploadListItemEntity } from '../../../types/entities/upload-list-item';
import { UPLOAD_LIST_FILE_STATUS } from '../../../utils/const';
import { __ } from '../../../utils/i18n';
import { getInstance } from '../../../utils/uploader';
import store, { RootState } from '../../../utils/store';
import * as UploadListAction from '../../../reducers/upload-list-reducer';
import * as SectionUploadActions from '../../sections/actions/upload';
import UploadListRow from './row';
import UploadListRowUploading from './row-uploading';
import { displayFileSize } from '../../../utils/helpers';

import '../assets/style.scss';

interface Props {
    sectionHash: string;
}

interface PropsConnected extends Props {
    files: { [fileId: string]: UploadListItemEntity };
    uploadInProgress: boolean;
}

const UploadList = (props: PropsConnected) => {
    const { sectionHash, files, uploadInProgress } = props;
    const { ref } = useResizeDetector({
        handleHeight: true,
        onResize: () => {
            return;
        },
    });

    const countFilesWithErrorsFn = () => {
        let count = 0;

        map(files, (file) => {
            if (file.errors.length > 0) {
                count++;
            }
        });

        return count;
    };

    const countFilesWithErrors = countFilesWithErrorsFn();
    const handleStartUpload = () => {
        store.dispatch(SectionUploadActions.start(sectionHash));

        getInstance(sectionHash).start();
    };
    const handleRemove = (item: UploadListItemEntity) => {
        if (item.status === UPLOAD_LIST_FILE_STATUS.READY || item.status === UPLOAD_LIST_FILE_STATUS.ERROR) {
            getInstance(sectionHash).removeFile(item.file.id);
        }

        store.dispatch(UploadListAction.remove(sectionHash, item.file.id));
    };
    const handleRemoveAll = () => {
        map(files, (item) => {
            handleRemove(item);
        });
    };
    const renderFiles = () => {
        return map(files, (item) => {
            return item.status === UPLOAD_LIST_FILE_STATUS.UPLOADING ? (
                <UploadListRowUploading
                    sectionHash={sectionHash}
                    item={item}
                    key={`${sectionHash}_${item.file.id}_uploading`}
                />
            ) : (
                <UploadListRow item={item} handleRemove={handleRemove} key={`${sectionHash}_${item.file.id}`} />
            );
        });
    };

    const sumFilesSize = () => {
        const sizes = map(files, (item) => {
            if (item.errors.length === 0) {
                return item.file.origSize;
            }
            return 0;
        });

        return displayFileSize(sizes.reduce((acc, item) => acc + item));
    };
    return (
        <div className="upload-list">
            <div className="header">
                <div className="file-info">
                    {__('Ilość plików')}:{Object.keys(files).length}{' '}
                    <span className="file-size">{`(${sumFilesSize()})`}</span>
                </div>
                {/* <div className="toggle">
                    <ExpandIcon />
                </div> */}
                <div className="remove-all" onClick={handleRemoveAll}>
                    {__('Usuń wszystkie')}
                </div>
            </div>

            <div ref={ref} id={`section_files_${sectionHash}`} className="files-list">
                {renderFiles()}
            </div>

            <button
                className="button-send"
                onClick={handleStartUpload}
                disabled={uploadInProgress || countFilesWithErrors === Object.keys(files).length}
            >
                {__('Prześlij pliki')}
            </button>
        </div>
    );
};

const mapStateToProps = (state: RootState, props: Props) => {
    return {
        files: state.uploadList[props.sectionHash],
        uploadInProgress: !!state.app.sectionWithUploadProgress.find((item) => item === props.sectionHash),
    };
};

export default connect(mapStateToProps)(UploadList);
