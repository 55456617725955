import { useEffect, useRef, useState } from 'react';

export const usePortal = () => {
    const [open, setOpen] = useState(false);
    const portalRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        portalRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, [open]);

    useEffect(() => {
        const handleClickOutsidePortal = (event: MouseEvent) => {
            if (event.target instanceof HTMLElement && !portalRef.current?.contains(event.target)) {
                setOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutsidePortal);

        return () => {
            document.removeEventListener('mousedown', handleClickOutsidePortal);
        };
    }, [portalRef]);

    const handleOpenPortal = () => {
        setOpen(true);
    };

    const handleClosePortal = () => {
        setOpen(false);
    };

    return { open, handleClosePortal, handleOpenPortal, portalRef };
};
