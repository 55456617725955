import store from './store';
import { __ } from './i18n';
import { getApi, getFileExtension, getProjectId, getToken, isAvilableThumbForFile, isPDFFile } from './helpers';

import { PlUploadFileEntity } from '../types/entities/plupload-file';
import { UploadListItemEntity } from '../types/entities/upload-list-item';
import { UPLOAD_LIST_FILE_STATUS } from './const';
import { getPDFFileInfo, getUploadFileDimensions } from '../modules/upload-list/services/info';
import { loadFile } from '../modules/files/services/files';
import * as FilesActions from '../modules/files/actions/files';
import { getNextPosition, saveSorting } from '../modules/files/services/sorting';
import * as SectionUploadActions from '../modules/sections/actions/upload';
import { validateFile } from '../modules/upload-list/services/validation';
import { validateSection } from '../modules/sections/services/validation';
import * as UploadListAction from '../reducers/upload-list-reducer';
const createUploadListItem = (file: PlUploadFileEntity): UploadListItemEntity => {
    return {
        file,
        status: UPLOAD_LIST_FILE_STATUS.WAITING,
        errors: [],
        progress: 0,
        dimensions: null,
    };
};
interface ErrorWithMessage {
    message: string;
}

const instances: { [key: string]: plupload.Uploader } = {};

const FileUploaded = async (up: plupload.Uploader, file: PlUploadFileEntity, result: plupload_response) => {
    const { sectionHash } = up.settings.multipart_params;
    const response = JSON.parse(result.response);

    try {
        const newFile = await loadFile(sectionHash, response.result.id);
        newFile.position = getNextPosition(sectionHash);

        //oznaczanie problematycznych plików - przesunięcia
        // if (isPDFFile(getFileExtension(newFile.name))) {
        //     const PDF = await getPDFFileInfo(file);
        //     if (PDF.problematic) problematicFile(sectionHash, response.result.id);
        // }

        // console.log('-> new file', newFile);
        //
        // const existingPdfBytes = await fetch(newFile.url).then((res) => res.arrayBuffer());
        // const pdfDoc = await PDFDocument.load(existingPdfBytes);
        //
        // console.log('-> pdfDoc: ', pdfDoc.getPageCount());
        //
        // const pages = pdfDoc.getPages();
        //
        // pages.map((page) => {
        //     console.log('-> page: ', page.getSize());
        // });

        store.dispatch(FilesActions.add(newFile));
        store.dispatch(UploadListAction.remove(sectionHash, file.id));

        saveSorting(sectionHash);
        validateSection(sectionHash);

        if (file.status === plupload.DONE) {
            up.removeFile(file);
        }

        up.refresh();
    } catch (e) {
        setTimeout(() => {
            FileUploaded(up, file, result);
        }, 2000);
    }
};

const defaults = {
    browse_button: null,
    url: '',
    runtimes: 'html5',
    multipart_params: {
        progress: {},
    },
    headers: {
        Accept: 'application/json',
        Authorization: `Hash ${getToken()}`,
    },
    init: {
        Init: (up: plupload.Uploader): void => {
            // URL upload
            const { sectionHash } = up.settings.multipart_params;
            up.settings.url = `${getApi()}/projects/${getProjectId()}/sections/${sectionHash}/files/upload`;

            // Drag and drop
            const dropElement = up.settings.drop_element[0];

            dropElement.ondragover = function (event: DragEvent) {
                if (event.dataTransfer) {
                    event.dataTransfer.dropEffect = 'copy';
                }
            };

            dropElement.ondragenter = function () {
                this.classList.add('dragover');
            };

            dropElement.ondragleave = function () {
                this.classList.remove('dragover');
            };

            dropElement.ondrop = function () {
                this.classList.remove('dragover');
            };
        },
        FilesAdded: async (up: plupload.Uploader, files: PlUploadFileEntity[]) => {
            const { sectionHash } = up.settings.multipart_params;
            const promises: Promise<UploadListItemEntity>[] = [];

            files.forEach((file) => {
                promises.push(
                    new Promise(async (resolve) => {
                        const item = createUploadListItem(file);
                        item.status = UPLOAD_LIST_FILE_STATUS.READY;

                        try {
                            if (isAvilableThumbForFile(getFileExtension(item.file.name))) {
                                item.dimensions = await getUploadFileDimensions(file);
                            }

                            if (isPDFFile(getFileExtension(item.file.name))) {
                                item.pdf = await getPDFFileInfo(file);
                            }
                        } catch (error: unknown) {
                            item.errors.push((error as ErrorWithMessage).message);
                            item.status = UPLOAD_LIST_FILE_STATUS.ERROR;

                            up.removeFile(file);
                            resolve(item);
                        }

                        const error = validateFile(sectionHash, item);

                        if (error !== true) {
                            item.errors.push(error as string);
                            item.status = UPLOAD_LIST_FILE_STATUS.ERROR;

                            up.removeFile(file);
                        }

                        resolve(item);
                    }),
                );
            });

            store.dispatch(UploadListAction.add(sectionHash, await Promise.all(promises)));

            up.refresh();
        },
        BeforeUpload: function (up: plupload.Uploader, file: PlUploadFileEntity) {
            const { sectionHash } = up.settings.multipart_params;
            store.dispatch(UploadListAction.setFileStatus(sectionHash, file.id, UPLOAD_LIST_FILE_STATUS.UPLOADING));
        },
        UploadProgress: (up: plupload.Uploader, file: PlUploadFileEntity) => {
            const { sectionHash } = up.settings.multipart_params;
            store.dispatch(UploadListAction.setUploadProgress(sectionHash, file.id, file.percent));
        },
        FileUploaded,
        UploadComplete: (up: plupload.Uploader) => {
            up.refresh();

            const { sectionHash } = up.settings.multipart_params;
            store.dispatch(SectionUploadActions.stop(sectionHash));
        },
        Error: async (up: plupload.Uploader, error: plupload_error) => {
            let errorInfo: string;
            const { sectionHash } = up.settings.multipart_params;

            switch (error.code) {
                case plupload.FILE_SIZE_ERROR:
                    errorInfo = `${__('Przekroczony dozwolony rozmiar pliku')}: ${up.settings.filters.max_file_size}`;
                    break;
                case plupload.FILE_DUPLICATE_ERROR:
                    errorInfo = __('Wybrany plik już został dodany');
                    break;
                case plupload.FILE_EXTENSION_ERROR:
                    errorInfo = __('Niepoprawny typ pliku');
                    break;
                default:
                    errorInfo = __('Błąd podczas próby dodania pliku');
            }

            const item = createUploadListItem(error.file);

            try {
                if (isAvilableThumbForFile(getFileExtension(error.file.name))) {
                    item.dimensions = await getUploadFileDimensions(error.file);
                }
            } catch (error: unknown) {
                const customError = error as ErrorWithMessage;
                errorInfo = __(customError.message);
            }

            item.errors.push(errorInfo);
            item.status = UPLOAD_LIST_FILE_STATUS.ERROR;

            store.dispatch(UploadListAction.add(sectionHash, [item]));
        },
    },
};

export const createInstance = (sectionHash: string, config: Record<string, unknown>): plupload.Uploader => {
    const up = new plupload.Uploader(Object.assign({}, defaults, config));
    up.init();
    instances[sectionHash] = up;

    return up;
};

export const getInstance = (sectionHash: string): plupload.Uploader => {
    return instances[sectionHash];
};
