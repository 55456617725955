import * as React from 'react';
import Modal from 'react-responsive-modal';
import { __ } from '../../../utils/i18n';

interface Props {
    multiple?: boolean;
    filesCount?: number;
    fileName?: string;
    onClose: () => void;
    removeHandler: () => void;
}

const FileModalDelete = (props: Props) => {
    const { multiple, fileName, onClose, removeHandler, filesCount } = props;

    const message = multiple
        ? __('Czy na pewno chcesz usunąć z projektu następującą ilość plików:') + ' ' + filesCount
        : __('Czy na pewno chcesz usunąć z projektu plik:') + ' ' + fileName;

    return (
        <Modal
            open={true}
            onClose={onClose}
            center={true}
            classNames={{
                modal: 'uploader-custom-modal',
                closeButton: 'uploader-custom-modal-close-button',
                closeIcon: 'uploader-custom-modal-close-button-icon',
            }}
        >
            <div className="title">{__('Usuwanie')}</div>
            <div className="content">{message}</div>
            <div className="footer">
                <button className="button secondary" onClick={onClose}>
                    {__('Anuluj')}
                </button>
                <button className="button danger" onClick={removeHandler}>
                    {__('Usuń')}
                </button>
            </div>
        </Modal>
    );
};
export default FileModalDelete;
