import React from 'react';
import './uploader-pdf-preview.scss';

interface UploaderPdfPreviewProps {
    url: string;
    portalRef: React.MutableRefObject<HTMLDivElement | null>;
    fileName: string;
    onClose: () => void;
}

export const UploaderPdfPreview = ({ portalRef, url, fileName, onClose }: UploaderPdfPreviewProps) => {
    return (
        <div className="uploader-pdf-preview-portal">
            <div className="uploader-pdf-preview-portal__container">
                <span className="close" onClick={onClose}>
                    x
                </span>
                <div className="uploader-pdf-preview-portal__container__iframe" ref={portalRef}>
                    <span>{fileName}</span>
                    <div>
                        <iframe src={url} allowFullScreen />
                    </div>
                </div>
            </div>
        </div>
    );
};
