import * as React from 'react';
import { __ } from '../../../utils/i18n';
import IconWarning from '../../../components/svg-icons/warning';

const ErrorInfo = () => {
    return (
        <div className="mode-error-load-project">
            <IconWarning />
            <p>{__('Błąd pobierania danych projektu. Odśwież stronę')}</p>
        </div>
    );
};

export default ErrorInfo;
