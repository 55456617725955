import forOwn from 'lodash-es/forOwn';
import reduce from 'lodash-es/reduce';
import { ScriptLoaderEntity } from '../types/shared';

const TIMEOUT_INTERVAL = 10;
const filesStatus: { [key: string]: boolean } = {};

interface CustomScriptInterface extends HTMLScriptElement {
    onreadystatechange: { (): void } | null;
    readyState: string;
}

export const scriptLoader = (scripts: ScriptLoaderEntity[]): void => {
    scripts.map((item) => {
        const script = document.createElement('script') as CustomScriptInterface;

        forOwn(item, (value, key) => {
            if (key === 'src') {
                script[key] = value;
            } else {
                script.setAttribute(key, value);
            }
        });

        const head = document.getElementsByTagName('head')[0];
        let done = false;
        filesStatus[item.src] = false;

        script.onload = script['onreadystatechange'] = function () {
            if (
                !done &&
                (!this['readyState'] || this['readyState'] === 'loaded' || this['readyState'] === 'complete')
            ) {
                done = true;
                filesStatus[item.src] = true;
                script.onload = script['onreadystatechange'] = null;
                head.removeChild(script);
            }
        };
        head.appendChild(script);

        return true;
    });
};

export const whenScriptLoaded = (callback: () => void): void => {
    const status = reduce(filesStatus, (memo, item) => memo || item);
    if (!status) {
        setTimeout(() => {
            whenScriptLoaded(callback);
        }, TIMEOUT_INTERVAL);
    } else {
        callback();
    }
};
