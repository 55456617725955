import * as React from 'react';
import { __ } from '../../utils/i18n';

import './assets/style.scss';

interface Props {
    size?: 'small' | 'mini' | 'big';
    light?: string;
    title?: string;
}

const Loader = (props: Props) => {
    const { size, light, title } = props;

    return (
        <div
            className={`
  nf-cart-loader
  ${size || ''}
  ${light ? 'light' : ''}
  ${title ? 'nf-pending-info' : ''}
  `}
        >
            <div className="nf-cart-loader-wrapper">
                <div className="nf-cart-loader-folding">
                    <span className="leaf1" />
                    <span className="leaf2" />
                    <span className="leaf3" />
                    <span className="leaf4" />
                </div>
                {!size || size === 'big' || title ? (
                    <span className="loading" data-name="Loading">
                        {title ? title : __('Ładowanie')}
                    </span>
                ) : (
                    ''
                )}
            </div>
        </div>
    );
};

export default Loader;
