import { toast } from 'react-toastify';

class ErrorHandler {
    show(msg: string) {
        toast.error(msg, {
            position: 'top-center',
        });
    }
}

export default ErrorHandler;
