import { SectionContainerEntity } from '../../../types/shared';
import { ProjectSectionEntity } from '../../../types/entities/project-section';
import { ProjectInfoEntity } from '../../../types/entities/project-info';
import { FileEntity } from '../../../types/entities/file';
import { createAction } from '@reduxjs/toolkit';

export const addConfig = createAction<SectionContainerEntity<ProjectSectionEntity>>('project/addConfig');

export const addInfo = createAction<ProjectInfoEntity>('project/addInfo');

export const addData = createAction<SectionContainerEntity<FileEntity[]>>('project/addData');
