import * as React from 'react';
import { connect } from 'react-redux';

import { ProjectSectionEntity } from '../../../types/entities/project-section';
import { FileEntity } from '../../../types/entities/file';
import { __ } from '../../../utils/i18n';
import FileItem from './file-item';
import { sortFiles } from '../../../utils/helpers';
import { RootState } from '../../../utils/store';

interface Props {
    sectionHash: string;
    index: number;
}

interface PropsConnected extends Props {
    sectionConfig: ProjectSectionEntity;
    sectionData: FileEntity[];
}

const Section = (props: Props & PropsConnected) => {
    const { index, sectionConfig, sectionData } = props;

    return (
        <div className="app-section">
            <div className="header">
                <div className="title-container">
                    <div className="title">
                        {index}. {__(sectionConfig.title)}
                    </div>
                </div>
                <div className="files-counter">
                    {__('Ilość')}
                    <span>{sectionData.length}</span>
                </div>
            </div>

            <div className="section-content">
                {[...sectionData].sort(sortFiles).map((file) => (
                    <FileItem
                        file={file}
                        key={`file_preview_${file.id}`}
                        sectionConfig={sectionConfig}
                        countFilesInSection={sectionData.length}
                    />
                ))}
            </div>
        </div>
    );
};
const mapStateToProps = (state: RootState, props: Props) => {
    const { sectionHash } = props;
    return {
        sectionConfig: state.config[sectionHash],
        sectionData: state.data[sectionHash],
    };
};

export default connect(mapStateToProps)(Section);
