import update from 'immutability-helper';
import * as ProjectActions from '../modules/app/actions/project';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const infoSlice = createSlice({
    name: 'info',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(ProjectActions.addInfo, (state, action) => {
            return update(state, { $set: action.payload });
        });
    },
});

export default infoSlice.reducer;
