/* tslint:disable */
import React from 'react';

const IconSort = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
        <path
            d="M14.1667 3.33331V16.6666M14.1667 16.6666L10.8333 13.3333M14.1667 16.6666L17.5 13.3333M5.83333 16.6666V3.33331M5.83333 3.33331L2.5 6.66665M5.83333 3.33331L9.16667 6.66665"
            stroke="#20CBB9"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default IconSort;
